import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { getTextsData } from "selectors/texts";
import { useDispatch, useSelector } from "react-redux";
import Activity from "nlib/common/Activity";
import AwaitingButton from "nlib/common/AwaitingButton";
import CommentsActions from "actions/CommentsActions";
import DataConstants from "const/DataConstants";
import DocumentPreview from "mlib/common/DocumentPreview";
import EventsActions from "actions/EventsActions";
import FormLabel from "nlib/common/FormLabel";
import MainApiActions from "actions/MainApiActions";
import Preloader from "nlib/common/Preloader";
import React, { useCallback, useLayoutEffect, useState } from "react";
import SideBar, { SideBarContent, SideBarHeader } from "nlib/common/SideBar";
import Utils from "utils/Utils";
import usePreventBodyScroll from "hooks/usePreventBodyScroll";

const {
  DOCUMENT_RECOGNISE_STATUSES,
  STATUSES: { TO_EXTRACT },
  COMMENT_TARGET_TYPES: { DOCUMENTS },
  DOCUMENT_TYPES: { INVOICE, BILL },
  DOCUMENT_PAYMENT_TYPES: { BUY }
} = DataConstants;

const ListItemDetails = ({ documentId, data, onClose }) => {
  const {
    type,
    status,
    comments,
    paymentType,
    attachment = {},
    originalDocumentId,
    duplicatedDocumentsIds,
    attachment: { key: attachmentKey } = {},
    recogniseData: { status: recogniseStatus, previewFile, previewPageMeta } = {}
  } = data || {};

  usePreventBodyScroll();

  const dispatch = useDispatch();

  const { uiTexts } = useSelector(getTextsData);

  const [activityLoaded, setActivityLoaded] = useState(false);

  const extractionStatus = status === TO_EXTRACT;

  const billType = paymentType === BUY && type === INVOICE;

  const typeContent = (paymentType && type && (billType ? BILL : type));

  const hasDuplicates = !!(Array.isArray(duplicatedDocumentsIds) && duplicatedDocumentsIds.length);

  const handleDownloadClick = useCallback(async() => {
    const link = await dispatch(MainApiActions.fetchAttachmentUrl(attachment, true));

    if (!link) return;

    Utils.downloadContent(link);
  }, [attachment, dispatch]);

  useLayoutEffect(() => {
    Promise.all([
      dispatch(CommentsActions.fetchComments(DOCUMENTS, documentId, true)),
      dispatch(EventsActions.fetchEventsByRoute(`/${DOCUMENTS}`, documentId))
    ]).then(() => {
      setActivityLoaded(true);
    });
  }, [dispatch, documentId]);

  if (!data) {
    return (
      <SideBar className={Css.listItemDetails}>
        <SideBarHeader titleClassName={Css.title} onCloseClick={onClose}>
          {uiTexts.document}
        </SideBarHeader>
        <Preloader />
      </SideBar>
    );
  }

  return (
    <SideBar className={Css.listItemDetails}>
      <SideBarHeader titleClassName={Css.title} onCloseClick={onClose}>
        <div>
          {extractionStatus
            ? uiTexts.document
            : <>
              {hasDuplicates
                ? <Icons.Copy className={Css.warning} />
                : <Icons.FileText className={attachmentKey ? "" : Css.negative} />}
              <span>
                {uiTexts[typeContent] || uiTexts.document}
                {!!originalDocumentId && `: ${originalDocumentId}`}
              </span>
            </>}
        </div>
        <AwaitingButton
          outline
          className={Css.button}
          onClick={handleDownloadClick}>
          <Icons.DownloadSimple />
        </AwaitingButton>
      </SideBarHeader>
      <SideBarContent>
        <div className={Css.content}>
          {activityLoaded
            ? (
              <>
                <div className={Css.col}>
                  <DocumentPreview
                    className={Css.preview}
                    error={recogniseStatus === DOCUMENT_RECOGNISE_STATUSES.ERROR}
                    previewPageMeta={previewPageMeta}
                    documentPreview={previewFile} />
                </div>
                <div className={Css.col}>
                  <FormLabel>{uiTexts.activity}</FormLabel>
                  <Activity
                    mobile
                    dataLoadedInitial
                    className={Css.activity}
                    itemId={documentId}
                    type={DOCUMENTS}
                    commentsCount={comments} />
                </div>
              </>
            )
            : <Preloader />}
        </div>
      </SideBarContent>
    </SideBar>
  );
};

export default React.memo(ListItemDetails);
