import Css from "./style.module.scss";

import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import Constants from "const/Constants";
import React, { useMemo } from "react";
import classNames from "classnames";
import colorInterpolate from "color-interpolate";

const { PERCENTS_MULTIPLIER } = Constants;

const NEGATIVE = "#e24e45";

const WARNING = "#ffad31";

const POSITIVE = "#12ab6b";

const ConfidencePercents = (props) => {
  const {
    className,
    value = 0,
    component: Component = "div",
    reverseColors,
    ...restProps
  } = props;

  const { uiTexts } = useSelector(getTextsData);

  const confidencePercents = Math.round(value * PERCENTS_MULTIPLIER);

  const style = useMemo(() => {
    return {
      [reverseColors ? "color" : "backgroundColor"]:
        colorInterpolate([NEGATIVE, WARNING, POSITIVE])(confidencePercents / PERCENTS_MULTIPLIER)
    };
  }, [confidencePercents, reverseColors]);

  return (
    <Component
      title={uiTexts.confidence}
      className={classNames(Css.confidencePercents, className)}
      style={style}
      {...restProps}>
      {confidencePercents ? `${confidencePercents}%` : "<1%"}
    </Component>
  );
};

export default React.memo(ConfidencePercents);
