import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { Button, Checkbox, Modal, UploadFilesButton } from "nlib/ui";
import { ModalFooter } from "nlib/ui/Modal";
import { getTextsData } from "selectors/texts";
import { useDispatch, useSelector } from "react-redux";
import Attachments from "nlib/common/Attachments";
import Constants from "const/Constants";
import React, { useCallback, useMemo, useState } from "react";
import UiActions from "actions/UiActions";
import Utils from "utils/Utils";

const { DOCUMENT_FILE_TYPES } = Constants;

const { PNG, JPG, TIFF } = DOCUMENT_FILE_TYPES;

const FILE_DROP_ALLOWED_TYPES = Object.values(DOCUMENT_FILE_TYPES)
  .reduce((aggregator, { extensions, mimeType }) => [...aggregator, ...extensions, mimeType], []);

const IMAGE_TYPE_EXTENSIONS = [PNG, JPG, TIFF].map(({ extensions }) => extensions).flat();

const UploadDocumentsModal = ({ data: { files = [] } = {}, onClose }) => {
  const dispatch = useDispatch();

  const { uiTexts, messages } = useSelector(getTextsData);

  const [filesList, setFilesList] = useState(files);

  const [merge, setMerge] = useState(false);

  const handleAttachmentDelete = useCallback((index) => {
    setFilesList((prev) => {
      return prev.filter((file, fileIndex) => fileIndex !== index);
    });
  }, []);

  const attachments = useMemo(() => {
    return filesList.map((file, index) => ({ id: index, originalName: file.name || "", file }));
  }, [filesList]);

  const handleImportClick = useCallback(() => {
    onClose({ files: filesList, merge });
  }, [filesList, merge, onClose]);

  const handleCloseClick = useCallback(() => {
    onClose(null);
  }, [onClose]);

  const handleCheckboxChange = useCallback(() => {
    setMerge((prev) => !prev);
  }, []);

  const handleFilesChange = useCallback((filesToAdd) => {
    setFilesList((prev) => [...prev, ...filesToAdd]);
  }, []);

  const handleAttachmentClick = useCallback((item) => {
    const { originalName = "", file } = item;

    const typeImage = IMAGE_TYPE_EXTENSIONS.includes(`.${originalName.split(".").pop()}`);

    if (typeImage && file) {
      dispatch(UiActions.showModalImages([URL.createObjectURL(file)]));
    }
  }, [dispatch]);

  return (
    <Modal
      className={Css.uploadDocumentsModal}
      iconComponent={Icons.FileText}
      title={uiTexts.uploadDocuments}
      onClose={onClose}>
      <Attachments
        showAll numbered
        useConfirm={false}
        attachments={attachments}
        onFileClick={handleAttachmentClick}
        onDelete={handleAttachmentDelete} />
      <div className={Css.merge}>
        <Button outline>
          <Checkbox
            toggle
            checked={merge}
            label={messages.mergeDocumentsCheckbox}
            onChange={handleCheckboxChange} />
        </Button>
      </div>
      <div className={Css.actions}>
        {Utils.checkIsNativeAndroid() && (
          <UploadFilesButton
            outline dark
            className={Css.button}
            icon={Icons.Camera}
            capture="environment"
            accept={["image/*"]}
            onChange={handleFilesChange}>
            {uiTexts.takePhoto}
          </UploadFilesButton>
        )}
        <UploadFilesButton
          primary outline multiple
          className={Css.button}
          icon={Icons.FilePlus}
          accept={FILE_DROP_ALLOWED_TYPES}
          onChange={handleFilesChange}>
          {Utils.checkIsNativeAndroid()
            ? uiTexts.selectFiles
            : (merge ? uiTexts.addMorePages : uiTexts.addMoreDocuments)}
        </UploadFilesButton>
      </div>
      <ModalFooter>
        <Button
          primary large
          disabled={!filesList.length || (merge && filesList.length === 1)}
          onClick={handleImportClick}>
          {filesList.length
            ? Utils.replaceTextVars(
              merge ? uiTexts.mergeCountPages : uiTexts.uploadCountDocuments,
              { count: filesList.length }
            )
            : (merge ? uiTexts.mergeDocuments : uiTexts.uploadDocuments)}
        </Button>
        <Button
          large outline
          onClick={handleCloseClick}>
          {uiTexts.close}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default React.memo(UploadDocumentsModal);
